import {
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  CreatedAt,
  DateType,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSerializer,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  IntegerFilterType,
  ItemGqlError,
  UUID,
  UpdatedAt
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import {
  GeneralLedgerCompanyID,
  GeneralLedgerCompanyImageFile,
  GeneralLedgerCompanyName,
  GeneralLedgerCompanyNanoID,
  GeneralLedgerCompanyOwnerID,
  GeneralLedgerCompanyOwnerNanoID,
  GeneralLedgerID,
  GeneralLedgerName
} from '../generalLedgers/generalLedgersTypes';
import { SmartContractID } from '../smartContracts/smartContractsTypes';
import {
  UserClient,
  UserFullName,
  UserID,
  UserImageFile,
  UserName,
  UserNanoID,
  UserSurname,
  UserUUID
} from '../users/usersTypes';

export type SmartContractShareID = ID;
export type SmartContractShareUUID = UUID;
export type SmartContractShareCreatedAt = CreatedAt;
export type SmartContractShareUpdatedAt = UpdatedAt;
export type SmartContractShareIteration = number;
export type SmartContractShareShare = number;
export type SmartContractShareFinalAt = DateType;
export type SmartContractShareShareType = SmartContractShareShareTypes;
export type SmartContractShareStatus = SmartContractShareStatuses;

export type SmartContractShareGeneralLedgerID = GeneralLedgerID;
export type SmartContractShareGeneralLedgerName = GeneralLedgerName;
export type SmartContractShareGeneralLedgerCompanyID = GeneralLedgerCompanyID;
export type SmartContractShareGeneralLedgerCompanyNanoID =
  GeneralLedgerCompanyNanoID;
export type SmartContractShareGeneralLedgerCompanyName =
  GeneralLedgerCompanyName;
export type SmartContractShareGeneralLedgerCompanyImageFile =
  GeneralLedgerCompanyImageFile;
export type SmartContractShareGeneralLedgerCompanyOwnerNanoID =
  GeneralLedgerCompanyOwnerNanoID;
export type SmartContractShareGeneralLedgerCompanyOwnerID =
  GeneralLedgerCompanyOwnerID;
export type SmartContractShareGeneralLedgerCompanyOwnerFullName = UserFullName;
export type SmartContractShareGeneralLedgerCompanyOwnerClient = UserClient;
export type SmartContractShareGeneralLedgerCompanyOwnerImageFile =
  UserImageFile;

export type SmartContractShareUserID = UserID;
export type SmartContractShareUserUUID = UserUUID;
export type SmartContractShareUserNanoID = UserNanoID;
export type SmartContractShareUserName = UserName;
export type SmartContractShareUserSurname = UserSurname;
export type SmartContractShareUserFullName = UserFullName;

export type SmartContractShareSmartContractID = SmartContractID;

export type FetchSmartContractSharesCacheKey = FetchItemCacheKey;
export type FetchSmartContractSharesSort = FetchItemsSort;
export type FetchSmartContractSharesPage = FetchItemsPage;
export type FetchSmartContractSharesLimit = FetchItemsLimit;

export type FetchSmartContractSharesSortSmartContractShares =
  FetchItemsSortItems;
export type FetchSmartContractSharesSerializer = FetchItemsSerializer;
export type FetchSmartContractSharesErrorMessage = FetchItemsErrorMessage;
export type FetchSmartContractSharesFetched = FetchItemsFetched;
export type FetchSmartContractShareFetched = FetchItemFetched;
export type FetchSmartContractSharesIsPlaceholderData =
  FetchItemsIsPlaceholderData;

export type FetchSmartContractSharesPaginateSmartContractShares =
  FetchItemsPaginateItems;
export type FetchSmartContractSharesPrefetchSmartContractShares =
  FetchItemsPrefetchItems;
export type FetchSmartContractSharesTotalCount = FetchItemsTotalCount;

export type CreateSmartContractShareGqlQuery = CreateItemGqlQuery;
export type CreateSmartContractShareGqlStatus = CreateItemGqlStatus;

export type CheckInSmartContractShareGqlQuery = CreateItemGqlQuery;
export type CheckInSmartContractShareGqlStatus = CreateItemGqlStatus;

export type UpdateSmartContractSharesCacheKeys = UpdateItemsCacheKeys;

export type SmartContractShareGqlError = ItemGqlError;

export type FetchSmartContractSharesGqlQuery = FetchItemsGqlQuery;

export type SmartContractShareStatusFilter = {
  eq?: SmartContractShareStatuses;
  in?: [SmartContractShareStatuses];
  notEq?: SmartContractShareStatuses;
  notIn?: [SmartContractShareStatuses];
};

export interface FetchFinSmartContractSharesFilters {
  smartContractId?: IdFilterType;
  userId?: IntegerFilterType;
  status?: SmartContractShareStatusFilter[];
  client?: boolean;
}

export interface FetchSmartContractSharesFilters {
  smartContractId?: SmartContractID;
  status?: SmartContractShareStatus[];
}

export const enum SmartContractShareShareTypes {
  WITH_INVOICE = 'with_invoice',
  WITHOUT_INVOICE = 'without_invoice'
}

export const enum SmartContractShareStatuses {
  NONE = 'none',
  INVITED = 'invited',
  CHECKED_IN = 'checked_in',
  CHECKED_OUT = 'checked_out',
  DISABLED = 'disabled'
}
