import React from 'react';

import { SmartContractID } from '../../../../smartContracts/smartContractsTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  SmartContractShareID,
  SmartContractShareStatuses,
  UpdateSmartContractSharesCacheKeys
} from '../../../../smartContractShares/smartContractSharesTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { CheckInSmartContractShareButton } from '../../../../smartContractShares/components/buttons/CheckInSmartContractShareButton';
import { CreateSmartContractShareModalButton } from '../../../../smartContractShares/components/modalButtons/CreateSmartContractShareModalButton';

import { IconsEnum } from '../../../../../assets/icons/types';
import { tasksKeys } from '../../../../../locales/keys';

interface TaskCheckInToSmartContractShareButtonProps {
  smartContractId?: SmartContractID;
  checkInSmartContractShareId?: SmartContractShareID;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  disabled?: IsDisabled;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipI18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  afterCheckIn?: () => void;
}

function TaskCheckInToSmartContractShareButton({
  smartContractId,
  checkInSmartContractShareId,
  cacheKeys,
  disabled,
  className,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  icon,
  iconClassName,
  afterCheckIn
}: TaskCheckInToSmartContractShareButtonProps) {
  const currentUser = useCurrentUser();

  return (
    <>
      {checkInSmartContractShareId && (
        <CheckInSmartContractShareButton
          className={className}
          disabled={disabled}
          smartContractShareId={checkInSmartContractShareId}
          cacheKeys={cacheKeys}
          i18nText={i18nText}
          i18nTextClassName={i18nTextClassName}
          tooltipI18nText={tooltipI18nText}
          icon={icon}
          iconClassName={iconClassName}
          afterCheckIn={afterCheckIn}
        />
      )}

      {!checkInSmartContractShareId && smartContractId && (
        <CreateSmartContractShareModalButton
          smartContractId={smartContractId}
          status={SmartContractShareStatuses.CHECKED_IN}
          i18nTitle={tasksKeys.buttons.checkIn}
          cacheKeys={cacheKeys}
          className={className}
          i18nText={i18nText}
          i18nTextClassName={i18nTextClassName}
          tooltipI18nText={tooltipI18nText}
          icon={icon}
          iconClassName={iconClassName}
          disabled={disabled}
          defaultUsers={[{ id: currentUser.id, client: currentUser.client }]}
          afterCreate={afterCheckIn}
        />
      )}
    </>
  );
}

export default TaskCheckInToSmartContractShareButton;
